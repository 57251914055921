import type { FetchRequest, FetchResponse } from 'ofetch'

import type {
  TNAPIPathsWithMethod,
  TNAPIQuery,
  TNAPINormalResponse,
  TNAPIHttpMethod,
  TNAPIBody,
  TNAPIPathParams
} from '~/utils/napi-helpers'

function _serializeOpenAPIPathParams<
  Method extends TNAPIHttpMethod,
  Path extends TNAPIPathsWithMethod<Method>
>(path: Path, _method: Method, pathParams?: TNAPIPathParams<Path, Method>) {
  // replace the path to pathParams
  let finalURL: string = path
  if (pathParams) {
    for (const [k, v] of Object.entries(pathParams)) {
      finalURL = finalURL.replace(`{${k}}`, encodeURIComponent(String(v)))
    }
  }
  return finalURL
}

function openAPIFetchOnce<
  Method extends TNAPIHttpMethod,
  Path extends TNAPIPathsWithMethod<Method>
>(
  path: Path,
  method: Method,
  pathParams?: TNAPIPathParams<Path, Method>,
  query?: TNAPIQuery<Path, Method>,
  body?: TNAPIBody<Path, Method>,
  oFetchOptions: Record<string, any> = {}
) {
  type TNormalResponse = TNAPINormalResponse<Path, Method>
  const urlWithPathParams = _serializeOpenAPIPathParams(path, method, pathParams)
  return fetchOnce<TNormalResponse>(urlWithPathParams, method, body, query, oFetchOptions)
}

function openApiReactiveList<Path extends TNAPIPathsWithMethod<'get'>>(
  path: Path,
  pathParams?: TNAPIPathParams<Path, 'get'>,
  query?: TNAPIQuery<Path, 'get'> extends Object ? TNAPIQuery<Path, 'get'> : never,
  asyncDataOptions?: Record<string, any>,
  oFetchOptions?: Record<string, any>
) {
  type TNormalResponse = TNAPINormalResponse<Path, 'get'>

  const urlWithPathParams = _serializeOpenAPIPathParams(path, 'get', pathParams)

  function fetchHandler() {
    const _query = removeFalsyValuesFromPayload(query as Object, ['*'])

    return fetchOnce<TNormalResponse>(urlWithPathParams, 'GET', undefined, _query, oFetchOptions)
  }
  const watchSources = []
  if (isRef(query) || isReactive(query)) {
    watchSources.push(query)
  }
  const asyncOptions = { ...asyncDataOptions }
  if (watchSources.length > 0) {
    asyncOptions.watch = watchSources
  }
  return useAsyncData<TNormalResponse>(
    `${urlWithPathParams}-${JSON.stringify(query)}`,
    fetchHandler,
    asyncOptions
  )
}

// TODO handle 401 / 403
function fetchOnce<T = unknown>(
  url: string,
  method: string,
  payload?: any,
  query?: any,
  oFetchOptions: Record<string, any> = {}
): Promise<T> {
  const toLocalePath = useLocalePath()
  const runtimeConfig = useRuntimeConfig()
  const regionState = useRegion()
  const { token: authToken } = useAuth()
  const uppercaseMethod = method.toUpperCase()
  const baseURL = process.client
    ? runtimeConfig.public.clientApiBaseUrl
    : runtimeConfig.serverApiBaseUrl
  const startTime = performance.now()
  const options: any = {
    baseURL,
    method: uppercaseMethod,
    headers: {
      Authorization: authToken?.value,
      'X-Line-Of-Business': runtimeConfig.public.lineOfBusiness,
      'X-Region-Of-Business': regionState.value
    }
  }
  options.query = query
  if (uppercaseMethod === 'POST' || uppercaseMethod === 'PUT') {
    options.body = payload
  }
  return $fetch(url, {
    ...options,
    onResponse() {
      const endTime = performance.now()
      console.log(`${url} took ${endTime - startTime} ms.`)
    },
    async onResponseError({
      request,
      response
    }: {
      request: FetchRequest
      response: FetchResponse<unknown>
    }) {
      const endTime = performance.now()
      console.log(`${url} took ${endTime - startTime} ms.`)
      // Capture meaningful fetch errors
      const errorMsg = `${response.status} - ${JSON.stringify(
        response._data
      )} for request ${request}`
      console.log('Error', errorMsg)

      const code = response.status
      if (
        authToken?.value &&
        (code === 401 || code === 403) &&
        !(
          url.includes('token') ||
          url.includes('sign-up') ||
          url.includes('password') ||
          url.includes('otp-request') ||
          url.includes('auth') ||
          url.includes('self')
        )
      ) {
        console.log('Your login session has expired. Please login again.')
        alert('Your login session has expired. Please login again.')
        return await navigateTo(toLocalePath('auth/logout'))
      }
    },
    ...oFetchOptions
  })
}

function loginWithPassword(
  email: string,
  password: string
): Promise<TNAPINormalResponse<'/napis/v1/auth/login', 'post'>> {
  const runtimeConfig = useRuntimeConfig()
  const regionState = useRegion()
  const baseURL = process.client
    ? runtimeConfig.public.clientApiBaseUrl
    : runtimeConfig.serverApiBaseUrl
  const method = 'post'
  const path = '/napis/v1/auth/login'
  const pathParams = undefined
  const query = undefined
  const body = {
    email,
    password
  }
  const uppercaseMethod = method.toUpperCase()
  const oFetchOptions: any = {
    baseURL,
    method: uppercaseMethod,
    headers: {
      'X-Line-Of-Business': runtimeConfig.public.lineOfBusiness,
      'X-Region-Of-Business': regionState.value
    }
  }
  return openAPIFetchOnce(path, method, pathParams, query, body, oFetchOptions)
}

function loginWithOTP(
  email: string,
  password: string
): Promise<TNAPINormalResponse<'/napis/v1/auth-user/otp-login/', 'post'>> {
  const runtimeConfig = useRuntimeConfig()
  const regionState = useRegion()
  const baseURL = process.client
    ? runtimeConfig.public.clientApiBaseUrl
    : runtimeConfig.serverApiBaseUrl
  const method = 'post'
  const path = '/napis/v1/auth-user/otp-login/'
  const pathParams = undefined
  const query = undefined
  const body = {
    email,
    password
  }
  const uppercaseMethod = method.toUpperCase()
  const oFetchOptions: any = {
    baseURL,
    method: uppercaseMethod,
    headers: {
      'X-Line-Of-Business': runtimeConfig.public.lineOfBusiness,
      'X-Region-Of-Business': regionState.value
    }
  }
  return openAPIFetchOnce(path, method, pathParams, query, body, oFetchOptions)
}

function requestOTP(
  email: string
): Promise<TNAPINormalResponse<'/napis/v1/auth-user/otp-request/', 'post'>> {
  const runtimeConfig = useRuntimeConfig()
  const regionState = useRegion()
  const baseURL = process.client
    ? runtimeConfig.public.clientApiBaseUrl
    : runtimeConfig.serverApiBaseUrl

  const method = 'post'
  const path = '/napis/v1/auth-user/otp-request/'

  const pathParams = undefined
  const query = undefined
  const body = {
    email
  }
  const uppercaseMethod = method.toUpperCase()
  const oFetchOptions = {
    baseURL,
    method: uppercaseMethod,
    headers: {
      'X-Line-Of-Business': runtimeConfig.public.lineOfBusiness,
      'X-Region-Of-Business': regionState.value
    }
  }
  return openAPIFetchOnce(path, method, pathParams, query, body, oFetchOptions)
}

function signup(payload: TNAPIBody<'/napis/v1/signup/', 'post'>) {
  const runtimeConfig = useRuntimeConfig()
  const regionState = useRegion()
  const baseURL = process.client
    ? runtimeConfig.public.clientApiBaseUrl
    : runtimeConfig.serverApiBaseUrl

  const method = 'post'
  const path = '/napis/v1/signup/'

  const pathParams = undefined
  const query = undefined
  const body = payload
  const uppercaseMethod = method.toUpperCase()
  const oFetchOptions = {
    baseURL,
    method: uppercaseMethod,
    headers: {
      'X-Line-Of-Business': runtimeConfig.public.lineOfBusiness,
      'X-Region-Of-Business': regionState.value
    }
  }
  return openAPIFetchOnce(path, method, pathParams, query, body, oFetchOptions)
}

function updateAuthUser(payload: TNAPIBody<'/napis/v1/auth-user/self/', 'put'>) {
  const { data: user } = useAuth()

  if (!user.value) return null
  return openAPIFetchOnce('/napis/v1/auth-user/self/', 'put', undefined, undefined, payload)
}

function _getContentType(name: string) {
  const extn = name.split('.').pop()
  let contentType = 'application/octet-stream'

  if (extn === 'pptx')
    contentType = 'application/vnd.openxmlformats-officedocument.presentationml.presentation'
  if (extn === 'doc') contentType = 'application/msword'
  if (extn === 'docx')
    contentType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
  if (extn === 'zip') contentType = 'application/zip'
  if (extn === '7z') contentType = 'application/x-7z-compressed'
  if (extn === 'rar') contentType = 'application/vnd.rar'
  if (extn === 'png') contentType = 'image/png'
  if (extn === 'jpg') contentType = 'image/jpeg'
  if (extn === 'jpeg') contentType = 'image/jpeg'
  if (extn === 'gif') contentType = 'image/gif'
  if (extn === 'pdf') contentType = 'application/pdf'

  return contentType
}

async function downloadPdfToFile(url: string, filename: string) {
  const res = await $fetch(url, { responseType: 'blob' })
  const file = new File([res as Blob], filename, {
    type: 'application/pdf'
  })
  console.log('file', file)
  return file
}

async function uploadFileToS3(
  fileUploadEndpoint: string,
  file: File,
  extraKwargs: Record<string, any>
) {
  if (!extraKwargs) {
    extraKwargs = {}
  }

  if (!file) {
    return null
  }
  const contentType = _getContentType(file.name)
  const fileRes: { data: { id_slug: string }; presigned_put_object_url: string } = await fetchOnce(
    fileUploadEndpoint,
    'POST',
    {
      ...extraKwargs,
      ...{
        display_name: `${file.name}`,
        content_type: contentType
      }
    }
  )

  const fileIdSlug = fileRes.data.id_slug
  const uploadFileSignedUrl = fileRes.presigned_put_object_url

  await $fetch(uploadFileSignedUrl, {
    method: 'PUT',
    body: file,
    headers: {
      'Content-Type': contentType
    }
  })

  return fileIdSlug
}

export function useAuthFetch() {
  return {
    openAPIFetchOnce,
    openApiReactiveList,
    fetchOnce,
    signup,
    loginWithOTP,
    loginWithPassword,
    requestOTP,
    updateAuthUser,
    uploadFileToS3,
    downloadPdfToFile
  }
}
